import Container from "react-bootstrap/Container";
import "./App.css";
import { Component } from "react";
import { Navbar, Spinner } from "react-bootstrap";
import cdkOutputObject from "./cdk-outputs.json";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Info from "./Info";
import logo from "./doctorIcon.png";
import ReactGA from "react-ga4";
import {
  loggedOutMessage,
  notLoggedInMessage,
} from "./constants";
import Login from "./Login";
import Scheduler from "./Scheduler";
import UserProfile from "./UserProfile";
import Footer from "./Footer";
import LivePairFinder from "./LivePairFinder";

if (cdkOutputObject.LCchatBackendStack.GAProprerty) {
  ReactGA.initialize("G-LV5NL64KB0");
  ReactGA.send("pageview");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "scheduler",

      // cognito login stuff
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: notLoggedInMessage,

      // feature flag
      schedulerEnabled:
        cdkOutputObject.LCchatBackendStack.schedulerEnabled === "true",

      // tutorial modal in scheduler and user profile pages
      showTutorialModal: true,
      disableTutorialChecked: false,
    };
  }

  handleSelectTab = (key) => {
    this.setState({ currentTab: key });
  };

  resetTokensOnLoginTimeout = () => {
    this.setState({
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: loggedOutMessage,
    });
  };

  // this is only called if login succeeds
  updateTokensOnLoginSuccess = (accessToken, idToken) => {
    this.setState({
      accessToken: accessToken,
      idToken: idToken,
      loggedIn: true,
    });

    // 59 minute login timeout, give 1 minute buffer against cognito 60 minutes
    setTimeout(this.resetTokensOnLoginTimeout, 60 * 59 * 1000);
  };

  onDisableTutorialChecked = (e) => {
    this.setState({ disableTutorialChecked: e.target.checked });

    // update local storage
    localStorage.setItem(
      "showTutorialModal",
      JSON.stringify(!e.target.checked)
    );
  };

  hideTutorialModalFunction = () => {
    this.setState({ showTutorialModal: false });
  };

  componentDidMount() {

    // load anything necessary here
    const showTutorialModel = localStorage.getItem("showTutorialModal");
    this.setState({
      showTutorialModal: showTutorialModel
        ? JSON.parse(showTutorialModel)
        : true,
    });
  }

  render() {

    return (
      <>
        <Container fluid>
          <Navbar bg="white" className="titleNavbar justify-content-center">
            <img src={logo} width="30" height="30" className="logo" />
            <Navbar.Brand className="titleText">
              Long Covid Chat
            </Navbar.Brand>
          </Navbar>
          <Tabs
            id="rootTab"
            activeKey={this.state.currentTab}
            onSelect={this.handleSelectTab}
            className="mb-3"
          >
            {this.state.schedulerEnabled ? (
              <Tab eventKey="scheduler" title="Scheduler" className="ms-auto">
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.LCchatBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.LCchatBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <Scheduler
                    isVisible={this.state.currentTab === "scheduler"}
                    showTutorialModal={this.state.showTutorialModal}
                    hideTutorialModalFunction={this.hideTutorialModalFunction}
                    onDisableTutorialChecked={this.onDisableTutorialChecked}
                  />
                </Login>
              </Tab>
            ) : null}
            {this.state.schedulerEnabled ? (
              <Tab
                eventKey="userProfile"
                title="User Profile"
                className="ms-auto"
              >
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.LCchatBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.LCchatBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <UserProfile />
                </Login>
              </Tab>
            ) : null}
            <Tab
              eventKey="partner"
              title="On-demand Chat"
              className="ms-auto"
            >
              <LivePairFinder />
            </Tab>
            <Tab eventKey="info" title="Information" className="ms-auto">
              <Info />
            </Tab>
          </Tabs>
          <Footer />
        </Container>
      </>
    );
  }
}

export default App;
